import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon,
	Form,
} from "semantic-ui-react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

class BackesExport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			focused: false,
			start: moment().startOf("day"),
		};
	}

	open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false });
	}

	render() {
		return (
			<Modal
				centered={false}
				className="duravermeerModal"
				trigger={
					<Button icon="file alternate outline" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.mixture")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form as="div">
							<Form.Field>
								<label>{this.props.t("exports.start")}</label>
								<SingleDatePicker
									date={this.state.start} // momentPropTypes.momentObj or null,
									id="xdpicker_start" // PropTypes.string.isRequired,
									numberOfMonths={3}
									isOutsideRange={(x) => false}
									onDateChange={(date) =>
										this.setState({
											start: date,
										})
									} // PropTypes.func.isRequired,
									focused={this.state.focused} // PropTypes.bool
									onFocusChange={({ focused }) => this.setState({ focused })}
									hideKeyboardShortcutsPanel
								/>
							</Form.Field>
						</Form>
						<form
							className="hiddenForm"
							method="get"
							action="https://dev-06.bpo-asphalt.de/ressourcen/BackesMixtureExport.php"
							target="_blank"
						>
							<input
								type="hidden"
								name="start"
								value={this.state.start.format("YYYY-MM-DD")}
							/>
							<Button positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(BackesExport)));
